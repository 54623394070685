import { SEGMENT_BY_PERSONNEL_ENTITY_TYPE } from "@modules/segment/interfaces/browse";
import { IControlOption, IControlOptions } from "../../interfaces/forms";
import { ICommonListStatusesByIds, IListType } from "../../interfaces/list";

import { PRODUCT_ENTITY_TYPES } from "./entity";

// enum
export enum LIST_TYPES {
  EMAIL = 1,
  DIRECT_MAIL,
  ALL,
}

export enum LIST_TYPES_NAME {
  EMAIL = "Email List",
  DIRECT_MAIL = "Direct Mail List",
  ALL = "All List",
}

export enum LIST_STATUS_ID_NAME {
  SAVED_FOR_LATER = "Saved For Later",
  EXPIRED = "Expired",
  ACTIVE = "Active",
  PROCESSING = "Processing",
  STATUS_PROCESSING_WITH_PERCENTS = "Uploading",
}

export enum LIST_STATUS_CLASSES {
  SAVED_FOR_LATER = "saved-for-later",
  EXPIRED = "expired",
  ACTIVE = "active",
  PROCESSING = "processing",
  STATUS_PROCESSING_WITH_PERCENTS = "uploading",
}

export enum LIST_RECORDS_TYPES {
  PEOPLE_ONLY = 1,
  BUILDINGS_ONLY,
  EDUCATOR_AT_HOME,
  BUILDINGS_AND_PERSONS,
}

export enum LIST_RECORDS_TYPES_NAME {
  PEOPLE_ONLY = "Personnel",
  BUILDINGS_ONLY = "Institution",
  EDUCATOR_AT_HOME = "Educator at Home",
  BUILDINGS_AND_PERSONS = "Institution & Personnel",
}

export interface IListRecordType {
  id: LIST_RECORDS_TYPES;
  name: LIST_RECORDS_TYPES_NAME;
}

export enum TARGETING_TYPES_ID {
  INSTITUTION = 1,
  GEOGRAPHY,
  PERSONNEL,
  DEMOGRAPHICS,
  PIDs,
  GRANT_DETAILS,
  RFP_IFB,
  CUSTOM_PIDs = 9,
  CUSTOM_PID_NID = 10,
  CANADIAN = 11,
}

export const ORDER_ViEW_TARGETING_TYPES: TARGETING_TYPES_ID[] = [
  TARGETING_TYPES_ID.GEOGRAPHY,
  TARGETING_TYPES_ID.INSTITUTION,
  TARGETING_TYPES_ID.PERSONNEL,
  TARGETING_TYPES_ID.RFP_IFB,
  TARGETING_TYPES_ID.GRANT_DETAILS,
  TARGETING_TYPES_ID.PIDs,
];

export enum TARGETING_TYPES_ID_CODE {
  PERSONNEL = "job",
  INSTITUTION = "institution type",
  GEOGRAPHY = "geography",
  DEMOGRAPHICS = "demographics",
  PIDs = "pid",
  CUSTOM_PIDs = "pid",
  CUSTOM_PID_NID = "pidNid",
  GRANT_DETAILS = "grant_details",
  RFP_IFB = "rf_ifb",
  CANADIAN = "canadian",
}

export enum TARGETING_TYPES_DESCRIPTION {
  PERSONNEL = "Personnel",
  INSTITUTION = "Institution Types",
  GEOGRAPHY = "Geography",
  DEMOGRAPHICS = "Demographics",
  PIDs = "Names & PIDs",
  GRANT_DETAILS = "Grant Details",
  RFP_IFB = "RFP & IFB Details",
}

export enum TARGETING_TYPES_DETAILS {
  PERSONNEL = "Teachers, Principals, District Personnel, etc.",
  INSTITUTION = "K-12, Districts, Colleges, etc.",
  GEOGRAPHY = "City, State, ZIP code, etc.",
  DEMOGRAPHICS = "Affluence Indicator, Household Income, etc.",
  PIDs = "Target individual institutions or PIDs",
  GRANT_DETAILS = "Grant Type, Grant Status, etc.",
  RFP_IFB = "Invitation for Bid (IFB) Publish Date, Sector, etc.",
}

// const
export const LIST_TYPES_DESC: IListType[] = [
  {
    id: LIST_TYPES.EMAIL,
    name: LIST_TYPES_NAME.EMAIL,
    description: "Includes email address.",
    priceDescription: "1 credit per record",
    canSetCpaToSegment: true,
    hasBuildingsOnly: false,
    dataId: "bl-rt-email-tile",
  },
  {
    id: LIST_TYPES.DIRECT_MAIL,
    name: LIST_TYPES_NAME.DIRECT_MAIL,
    description: "Does not include email address.",
    priceDescription: "0.35 credits per record",
    canSetCpaToSegment: true,
    hasBuildingsOnly: true,
    dataId: "bl-rt-direct-tile",
  },
  {
    id: LIST_TYPES.ALL,
    name: LIST_TYPES_NAME.ALL,
    description: "With or without an email address.",
    priceDescription:
      "0.35 credits per Direct Mail record<br/>1 credit per Email record",
    canSetCpaToSegment: true,
    hasBuildingsOnly: false,
    dataId: "bl-rt-all-tile",
  },
];

export const LIST_TYPES_MAP: { [key: number]: IListType } =
  LIST_TYPES_DESC.reduce(
    (res: ICommonListStatusesByIds<IListType>, type: IListType) => {
      res[type.id] = type;
      return res;
    },
    {},
  );

export const LIST_RELATE_TO_PERSONNEL_TYPE: LIST_RECORDS_TYPES[] = [
  LIST_RECORDS_TYPES.PEOPLE_ONLY,
  LIST_RECORDS_TYPES.EDUCATOR_AT_HOME,
  LIST_RECORDS_TYPES.BUILDINGS_AND_PERSONS,
];

export const LIST_COUNT_BY_RECORD_TYPES: Readonly<{ [key: number]: string }> =
  Object.freeze({
    [LIST_RECORDS_TYPES.BUILDINGS_ONLY]: "institutionsCount",
    [LIST_RECORDS_TYPES.PEOPLE_ONLY]: "personsCount",
    [LIST_RECORDS_TYPES.EDUCATOR_AT_HOME]: "personsCount",
  });

export const RECORDS_TYPES_OPTIONS: IControlOption[] = [
  {
    value: LIST_RECORDS_TYPES.PEOPLE_ONLY,
    label: LIST_RECORDS_TYPES_NAME.PEOPLE_ONLY,
  },
  {
    value: LIST_RECORDS_TYPES.BUILDINGS_ONLY,
    label: LIST_RECORDS_TYPES_NAME.BUILDINGS_ONLY,
  },
  {
    value: LIST_RECORDS_TYPES.EDUCATOR_AT_HOME,
    label: LIST_RECORDS_TYPES_NAME.EDUCATOR_AT_HOME,
  },
];

export const CDL_RECORDS_TYPES_OPTIONS: IControlOption[] = [
  {
    value: LIST_RECORDS_TYPES.BUILDINGS_ONLY,
    label: LIST_RECORDS_TYPES_NAME.BUILDINGS_ONLY,
  },
  {
    value: LIST_RECORDS_TYPES.BUILDINGS_AND_PERSONS,
    label: LIST_RECORDS_TYPES_NAME.BUILDINGS_AND_PERSONS,
  },
];

export const LIST_TYPES_DESC_OPTIONS: IControlOptions = LIST_TYPES_DESC.map(
  ({ id, name }: { id: number; name: string }) => ({
    value: id,
    label: name,
  }),
);

export const CDL_LIST_TYPES_FOR_BUILDINGS_AND_PERSONS_OPTIONS: IControlOptions =
  LIST_TYPES_DESC_OPTIONS.filter(
    (item: IControlOption) => item.value !== LIST_TYPES.DIRECT_MAIL,
  );

export const LIST_DELIVERY_TYPES_NAMES: Readonly<{ [key: number]: string }> =
  Object.freeze({
    [PRODUCT_ENTITY_TYPES.LIST]: "Download",
    [PRODUCT_ENTITY_TYPES.CUSTOM_LIST]: "Custom",
    [PRODUCT_ENTITY_TYPES.CDL]: "Strategic Data",
    [PRODUCT_ENTITY_TYPES.DEPLOY_ONLY]: "Deploy Only",
  });

export const LIST_DELIVERY_TYPE_OPTIONS: IControlOptions = [
  {
    value: PRODUCT_ENTITY_TYPES.LIST,
    label: LIST_DELIVERY_TYPES_NAMES[PRODUCT_ENTITY_TYPES.LIST],
  },
  {
    value: PRODUCT_ENTITY_TYPES.CDL,
    label: LIST_DELIVERY_TYPES_NAMES[PRODUCT_ENTITY_TYPES.CDL],
  },
  {
    value: PRODUCT_ENTITY_TYPES.DEPLOY_ONLY,
    label: LIST_DELIVERY_TYPES_NAMES[PRODUCT_ENTITY_TYPES.DEPLOY_ONLY],
  },
];

export const SEGMENT_BY_ITEMS_NAME: Readonly<{
  [p: number]: string;
  GEOGRAPHY: string;
}> = Object.freeze({
  [SEGMENT_BY_PERSONNEL_ENTITY_TYPE.JOB]: "Job Title",
  [SEGMENT_BY_PERSONNEL_ENTITY_TYPE.SUBJECT]: "Subject",
  [SEGMENT_BY_PERSONNEL_ENTITY_TYPE.CATEGORY]: "Category",
  [SEGMENT_BY_PERSONNEL_ENTITY_TYPE.DISCIPLINE]: "Discipline",
  GEOGRAPHY: "Region",
});

export enum PRESET_TYPES_CODE {
  CANADIAN = "withCanadian",
}
