import { Params } from "@angular/router";

import { IEntityType } from "@shared/interfaces/entity";
import { IListType } from "@shared/interfaces/list";
import { IManageAccountLists } from "@shared/interfaces/manage-account-lists";
import { IServerError } from "@shared/interfaces/server-error";

import { LIST_STATUS_ID } from "@shared/constants/data/list-statuses";
import { MANAGE_ACCOUNT_LISTS } from "@shared/constants/data/manage-account-lists";
import { DASHBOARD_ENTITY_TYPES } from "../constants/dashboard";

export interface IFilters {
  dateCreated: IDateRange;
  lastModified: IDateRange;
  leads: INumberRange;
  status: IFiltersStatuses;
  types: IListType[];
  entityTypes: IListType[];
  count: number;
  pendingRemoval: boolean;
  showAccountLists?: IManageAccountLists;
}

export type IFiltersStatuses = LIST_STATUS_ID[];

export interface IFilterQueryParams {
  entityType?: DASHBOARD_ENTITY_TYPES;
  showAccountLists?: MANAGE_ACCOUNT_LISTS;
  deployOnly?: number;
}

export interface IDashboardProductQueryParams {
  leadsMin?: number;
  leadsMax?: number;
  entityType?: number[];
  showDeployOnly?: number;
  type?: number[];
  status?: number[];
  lastModifiedMin?: number;
  lastModifiedMax?: number;
  dateCreatedMin?: number;
  dateCreatedMax?: number;

  offset?: number;
  limit?: number;

  sortDirection?: string;
  sortBy?: string;

  query?: string;
  showAccountLists?: MANAGE_ACCOUNT_LISTS;
}

export interface IDateRange {
  min: number;
  max: number;
}

export interface INumberRange {
  min: number;
  max: number;
}

export interface IFiltersResult {
  leadsMin?: number;
  leadsMax?: number;
  type?: number[];
  entityType?: number[];
  showDeployOnly?: number;
  status?: number[];
  showPendingRemoval?: number;
  // timestamps
  lastModifiedMin?: number;
  lastModifiedMax?: number;
  dateCreatedMin?: number;
  dateCreatedMax?: number;
  showAccountLists?: MANAGE_ACCOUNT_LISTS;
}

export interface IProductNavigationPayload {
  productId: number;
  url: Array<string | number>;
  queryParams?: Params;
}

export interface IRenameListPayload extends IEntityType {
  listId: number;
  name: string;
}

export interface IListAssign {
  id: number;
  email: string;
}

export enum DASHBOARD_VIEW_TYPE {
  PRODUCT,
  TABLE,
}

export interface IDashboardViewType {
  icon: string;
  type: DASHBOARD_VIEW_TYPE;

  dataId?: string;
}

export type IDashboardViewTypes = Array<IDashboardViewType>;

export const DASHBOARD_VIEW_TYPES: IDashboardViewTypes = [
  {
    icon: "icon-grid-view",
    type: DASHBOARD_VIEW_TYPE.PRODUCT,
    dataId: "bl-card-view-toggle",
  },
  {
    icon: "icon-list-view",
    type: DASHBOARD_VIEW_TYPE.TABLE,
    dataId: "bl-list-view-toggle",
  },
];

export const customPollErrorForInactiveDashboard: IServerError = {
  message: "DashboardInactive",
  errors: {
    inactiveDashboard: ["true"],
  },
};
